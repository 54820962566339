import { jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo } from "react";
const context = createContext(null);
function useCheckGroup() {
  return useContext(context);
}
function CheckGroupContextProvider({
  children,
  multiple,
  checkGroupId,
  required
}) {
  const providerValue = useMemo(
    () => ({ checkGroupId, required, multiple }),
    [checkGroupId, required, multiple]
  );
  return /* @__PURE__ */ jsx(context.Provider, { value: providerValue, children });
}
function convertValue(str) {
  if (/^(?:true|false)$/i.test(str)) {
    return str.toLowerCase() === "true";
  }
  const num = Number(str);
  return !isNaN(num) && str.trim() !== "" ? num : str;
}
export {
  CheckGroupContextProvider as C,
  convertValue as c,
  useCheckGroup as u
};
